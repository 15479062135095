.ant-descriptions-item-label {
  width: 25%;
}

.ant-form-item-label {
  white-space: normal;
}
.user-name{
  font-weight: bold;
  font-size: 18px;
}