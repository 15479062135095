.float-label {
  position: relative;
}

.label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 6px;
  transition: 0.2s ease all;
  z-index: 1;
}

.float-input:focus-within + .label {
  color: var(--ant-primary-color);
}

.float-select:focus-within + .label {
  color: var(--ant-primary-color);
}

.as-placeholder {
  color: gray;
}

.as-label {
  top: -8px;
  font-size: 12px !important;
  background: white;
  padding: 0 4px;
  margin-left: -4px;
}

.ant-form-item-explain-error {
  margin-bottom: 8px;
}
